
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "add-product-modal",
  directives: {mask}, 
  props: {
    data: {
        type: Object
    }
  },
  components: {
    
    },

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editProductModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const shape = ref([]);
    var formData = ref({})
    const setShapedata = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_GET_PRODUCT_SHAPE, db_data).then(({ body }) => {
          shape.value = body.shape_list;
          //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const parent = ref([]);
    const setParentData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term" : data}
          await store.dispatch(Actions.CUST_GET_PARENT_CATEGORY, values).then(({ data }) => {
          parent.value = data.page_data;
          //loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const making = ref([]);
    const setMakingdata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_PRODUCT_MAKING, param).then(({ body }) => {
            making.value = body.making_process_list;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const comp = ref([]);
    const setCompositiondata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_PRODUCT_COMPOSITION, param).then(({ body }) => {
            comp.value = body.composition_types_list;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const setFormData = async (data) => { 
      console.log('data.ids');   
        console.log(data.ids);
        var j = 0;

        try {

        var values = { "product_id": data.ids, "page": 1, "records_per_page": 1 }
         await store.dispatch(Actions.CUST_GET_PRODUCT_LIST, values).then(({ data }) => {       
          
          // for (var i = 0; i < data.tags.length; i++){
          //     var obj = data.tags[i];
          //     tag_list.push(obj.tag_id)
          // }

          // for (var j = 0; j < data.industry_types.length; j++){
          //     var industry_obj = data.industry_types[j];
          //     industry_type_list.push(industry_obj.company_industry_type_id)
          // }
          
          formData.value = {
            id: data[0].product_id,
            name: data[0].product_name,      
            description: data[0].product_description,
            short_name: data[0].product_short_name,
            parent_select: data[0].category_id,
            making_select: data[0].making_process_id,
            composition_select: data[0].composition_id,
            shape_select: data[0].shape_id,
            active : true,
            };
            console.log('formData.value')
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }
        
    const rules = ref({
      name: [
        {
          required: true,
          message: "Product Name is required",
          trigger: "change",  
        },        
      ],    
    });

    const setProductData = async (data) => {

      const db_data = {
        "product_id" : data.id,
        "product_name": data.name,
        "product_description": data.description,
        "product_short_name": data.short_name,
        "category_id": data.parent_select,    
        "shape_id": data.shape_select, 
        "making_process_id": data.making_select,
        "composition_id": data.composition_select,
        "created_user_id": "1",
        // "category_id_top": 1,
        "created_ip": "0.0.0.0",
        "created_uagent": "Test",
        "active": true
        }

        await store.dispatch(Actions.CUST_GET_PRODUCT_UPDATE, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editProductModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }
   
    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setProductData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);              
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };

    
    onMounted( async () => {
      await setFormData(props.data);
      await setParentData("")
      await setMakingdata(making.value)
      await setCompositiondata(comp.value)
      await setShapedata(shape.value)
    });
    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      editProductModalRef,
      setParentData,
      setMakingdata,
      setCompositiondata,
      setShapedata,
      shape,
      comp,
      making,
      parent,
    };
  },
});
